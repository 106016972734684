<template>
  <section class="forgot">
    <Loader :loading="showLoader" />
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-4">
                <div class="brand-logo">
                  <img src="../../assets/images/logo-new.png" />
                </div>
                <h4>Reset Password</h4>
                <h6 class="font-weight-light">
                  Enter your Email and instructions will be sent to you!
                </h6>
                <form class="pt-3" @submit.prevent="forgotPassword">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      v-model="email"
                      id="email"
                      name="email"
                      class="form-control form-control-lg"
                      :class="{ 'is-invalid': submitted && $v.email.$error }"
                    />
                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">Email is required</span>
                      <span v-if="!$v.email.email">Email is invalid</span>
                    </div>
                  </div>

                  <div class="mt-3">
                    <button
                      class="
                        btn btn-block btn-gradient-primary btn-lg
                        font-weight-medium
                        auth-form-btn
                      "
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    class="
                      my-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  ></div>

                  <div class="text-center mt-4 font-weight-light">
                    Already have an account?
                    <router-link to="/auth-pages/login" class="text-primary"
                      >Login</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { authApi } from "../../api";
import Loader from "../../components/Loader";
export default {
  name: "forgot",
  data() {
    return {
      email: "",
      submitted: false,
      showLoader: false,
    };
  },
  components: {
    Loader,
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async forgotPassword() {
      this.showLoader = true;
      const response = await authApi.forgotPassword(this.email);
      this.showLoader = false;
      if (response.status === 200) {
        this.$swal({
          type: "success",
          title: "Success",
          text: "Password reset link has been sent to your email",
        });
        this.$router.push("/auth-pages/reset");
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "Are you looking for something? It's not here!",
        });
      }
    },
  },
};
</script>
